.root {
  width: 100%;
  height: 100%;
}
.card {
  width: 100%;
  margin: 0px;
  height: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.image {
  height: 150px;
  width: 100%;
  padding: 4px;
}
.header {
  padding: 4px 8px;
  text-align: start;
}
.title {
  line-height: 22px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  margin-top: 0;
  -webkit-box-orient: vertical;
}
.actions {
  margin-inline-start: auto;
  padding-inline-end: 4px;
  padding-bottom: 4px;
}
