.listDetailedSkeletonItem {
    height: 100px;
    &__image,
    &__imageContainer {
        height: 100%;
        width: 100px;
        flex-grow: 0;
        border-radius: 4px;
    }
    &__detailsContainer {
        flex-grow: 2;
        margin-inline-start: 8px;
    }
    &__title {
        height: 32px;
        border-radius: 4px;
    }
    &__description {
        height: 54px;
        border-radius: 4px;
    }
}
