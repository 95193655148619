.root {
  margin: 0px;
  width: 100%;
  --ion-background-color: white;
}
.container {
  min-height: 150px;
  display: flex;
}
.image {
  margin: auto;
  padding: 4px;
  width: 120px;
  height: 150px;
}
.details {
  flex-grow: 1;
  padding: 8px;
}
.header {
  padding: 0;
  margin-bottom: 8px;
}
.content {
  padding: 0;
}
.title {
  font-size: 16px;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  margin-bottom: 4px;
  -webkit-box-orient: vertical;
}
.actions {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-inline-end: 4px;
  margin-bottom: 4px;
}
