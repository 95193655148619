.disabled-store {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 100vh;
    min-width: 100vw;
    background-color: var(--ion-background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    .disabled-store__content {
      text-align: center;
    }
    .logo__image {
      min-width: 120px;
    }
}