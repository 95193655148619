.submitLoader {
  height: 20px;
}
.submitButton {
  margin-top: 16px;
}

.title {
  text-align: start;
}
