.bookingDetailsItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 4px;
    &__value {
        color: var(--ion-color-step-850, #262626);
        font-weight: 600;
    }
    &__label {
        margin: auto 0;
    }
}
