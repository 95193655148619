.ion-margin-top-small {
    --margin-top: var(--ion-margin-small, 8px) !important;
    margin-top: var(--ion-margin-small, 8px) !important;
}
.ion-margin-end-small {
    --margin-end: var(--ion-margin-small, 8px);
    margin-right: var(--ion-margin-small, 8px);
}
.ion-margin-bottom-small {
    --margin-bottom: var(--ion-margin-small, 8px);
    margin-bottom: var(--ion-margin-small, 8px);
}
.ion-margin-horizontal-small {
    --margin-start: var(--ion-margin-small, 8px);
    --margin-end: var(--ion-margin-small, 8px);
    margin-left: var(--ion-margin-small, 8px);
    margin-right: var(--ion-margin-small, 8px);
}
.ion-margin-horizontal-xsmall {
    --margin-start: var(--ion-margin-xsmall, 4px);
    --margin-end: var(--ion-margin-xsmall, 4px);
    margin-left: var(--ion-margin-xsmall, 4px);
    margin-right: var(--ion-margin-xsmall, 4px);
}
.ion-margin-top-auto {
    margin-top: auto;
    --margin-top: auto;
}
.ion-margin-vertical-auto {
    --margin-top: auto;
    --margin-bottom: auto;
    margin-top: auto;
    margin-bottom: auto;
}
.ion-margin-start-auto {
    --margin-start: auto;
    margin-left: auto;
}
@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .ion-margin-small {
        margin-left: unset;
        margin-right: unset;
        margin-inline-start: var(--ion-margin-small, 8px);
        margin-inline-end: var(--ion-margin-small, 8px);
    }
    .ion-margin-horizontal-small {
        margin-left: unset;
        margin-right: unset;
        margin-inline-start: var(--ion-margin-small, 8px);
        margin-inline-end: var(--ion-margin-small, 8px);
    }
    .ion-margin-horizontal-xsmall {
        margin-left: unset;
        margin-right: unset;
        margin-inline-start: var(--ion-margin-xsmall, 4px);
        margin-inline-end: var(--ion-margin-xsmall, 4px);
    }
    .ion-margin-start-auto {
        margin-left: unset;
        margin-inline-start: auto;
    }
}

@supports (margin-inline-end: 0) or (-webkit-margin-end: 0) {
    .ion-margin-end-small {
        margin-right: unset;
        margin-inline-end: var(--ion-margin-small, 8px);
    }
}

.ion-margin-horizontal-auto {
    --margin-right: auto !important;
    margin-right: auto !important;
    --margin-left: auto !important;
    margin-left: auto !important;
}

.ion-margin-small {
    --margin-start: var(--ion-margin-small, 8px);
    --margin-end: var(--ion-margin-small, 8px);
    --margin-top: var(--ion-margin-small, 8px);
    --margin-bottom: var(--ion-margin-small, 8px);
    margin-left: var(--ion-margin-small, 8px);
    margin-right: var(--ion-margin-small, 8px);
    margin-top: var(--ion-margin-small, 8px);
    margin-bottom: var(--ion-margin-small, 8px);
}
