.default-home {
    &__carousel {
        height: 300px;
        border-radius: 0 0 40px 40px;
        --ion-background-color: white;
        .slide {
            height: 100%;
            display: flex;
            justify-content: space-between;
            padding: 20px 10px;
            width: 100%;
            &__image {
                flex-grow: 1;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                margin-left: 10px;
            }
            &__info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                text-align: left;
                width: 45%;
                h4 {
                    margin: 0;
                }
            }
        }
    }
    &__about {
        display: flex;
        margin: 20px;
        .logo {
            width: 100px;
            height: 100px;
            min-width: 100px;
            --ion-background-color: #fff;
            display: flex;
            align-items: center;
            border-radius: 20px;
            padding: 10px;
            ion-image {
                width: 100%;
                height: 100%;
            }
        }
        .description {
            width: 100%;
            margin-inline-start: 20px;
        }
    }
    &__about-btn {
        margin: 0 20px;
    }
    &__featured {
        margin-top: 6px;
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 20px;
        }
    }
    .carousel-container {
        --ion-background-color: white;
    }
}