.root {
  display: flex;
  padding: 0 5px;
  padding-top: 10px;
}
.logo {
  margin: auto 5px;
  height: fit-content;
  width: fit-content;
}
text {
  margin: auto 0;
}
