.authPage {
    display: flex;
    .authMode {
        margin: 0;
        padding: 10px;
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    .authBadge {
        white-space: break-spaces;
        width: 100%;
    }
    .resetButton {
        margin: 10px;
    }
}
