.slide {
  padding: 4px;
}
.imageContainer {
  width: 100%;
}
.imageCard {
  width: 100%;
  margin: 0;
}
.image {
  object-fit: cover;
  width: 100%;
}
