.serviceView {
    width: 100%;
    &__card {
        width: 100%;
        margin: 0;
    }
    &__image {
        height: 170px;
        object-fit: cover;
        width: 100%;
    }

    &__header {
        text-align: start;
    }
    &__title {
        font-size: 16px;
    }
    &__title,
    &__subtitle {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-top: 0;
    }
}
