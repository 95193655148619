.ion-font-bold {
    font-weight: bold;
}
.ion-font-bolder {
    font-weight: bolder;
}
.ion-font-normal {
    font-weight: normal;
}
.ion-font-light {
    font-weight: 300;
}
.ion-font-lighter {
    font-weight: lighter;
}
.ion-font-xsmall {
    font-size: x-small !important;
}
.ion-font-small {
    font-size: small !important;
}
.ion-font-medium {
    font-size: medium !important;
}
.ion-font-large {
    font-size: large !important;
}
.ion-font-larger {
    font-size: 20px !important;
}
.ion-font-xlarge {
    font-size: x-large !important;
}

.ion-font-color-danger {
    color: var(--ion-color-danger) !important;
    --color: var(--ion-color-danger) !important;
}
.ion-font-color-dark {
    color: var(--ion-color-dark) !important;
    --color: var(--ion-color-dark) !important;
}