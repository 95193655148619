.disabled-user {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .disabled-user__content {
    text-align: center;
  }
  .logo__image {
    min-width: 120px;
  }
}