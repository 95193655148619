.root {
  width: 100%;
  background-color: var(--ion-color-medium);
}
.horizontal {
  height: 1px;
}

.vertical {
  width: 1px;
}
