.bookingItem {
  &__card {
    width: 100%;
    padding: 8px;
  }
  &__serviceName {
    font-size: 18px;
    margin-bottom: 6px;
  }
  &__bookingDate {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 6px;
  }
  &__detailsContainer {
    flex-grow: 0;
  }
  &__total {
    font-size: 18px !important;
  }
}
