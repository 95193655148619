.cartButton {
    position: relative;
    &__itemsBadge {
        position: absolute;
        border-radius: 20px;
        line-height: 10px;
        font-size: 10px;
        height: 14px;
        padding:  2px 4px;
    }
    &__itemsBadge.ios{
        top: 0px;
        left: 0px;
    }
    &__itemsBadge.md{
        top: -6px;
        left: -6px;
    }
}
