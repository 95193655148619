.empty {
    p {
        position: absolute;
        margin: auto;
        bottom: 30%;
        transform: translate(0, 50%);
        text-align: center;
        width: 100%;
    }
}

.servicesCategoryListContainer {
    .header {
        position: sticky;
        top: 0;
        z-index: 300;
    }
    .service {
        padding: 4px;
        &__image {
            display: flex;
            justify-content: center;
            flex-grow: 1;
            width: 100%;
            height: 200px;
            img {
                max-height: 200px;
            }
        }
        &__info {
            margin-inline-start: 10px;
            .title {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        &__card {
            cursor: pointer;
            margin-inline: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            height: 100%;
            margin-top: 0px;
            margin-bottom: 0px;
            --ion-background-color: white;
            -webkit-margin-before: 0px;
            -webkit-margin-end: 0px;
            -webkit-margin-after: 0px;
            -webkit-margin-start: 0px;
            -webkit-padding-before: 0px;
            -webkit-padding-end: 0px;
            -webkit-padding-after: 0px;
            -webkit-padding-start: 0px;
        }
        &__header {
            padding-inline: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            margin: 10px;
        }
        &__content {
            padding-inline: 0px;
            padding-right: 10px;
            padding-left: 10px;
            padding-bottom: 5px;
            padding-top: 5px;
            -webkit-margin-before: 0px;
            -webkit-margin-end: 0px;
            -webkit-margin-after: 0px;
            -webkit-margin-start: 5px;
            -webkit-padding-before: 10px;
            -webkit-padding-end: 10px;
            -webkit-padding-after: 0px;
            -webkit-padding-start: 0px;
        }
        &__badge {
            padding-inline: 0px;
            padding-right: 10px;
            padding-left: 10px;
            padding-bottom: 0px;
            padding-top: 10px;
        }
        &__display {
            height: 210px;
        }
    }
    .category {
        width: 100%;
        margin: 0px;
        margin-left: 5px;
        padding-left: 5px;
        padding-right: 5px;
        &__title {
            margin-bottom: 0px;
            margin-top: 8px;
        }
    }
}

.custom__carousel {
    display: flex;
    overflow-x: auto;
    &__card {
        --ion-background-color: white;
        width: 47vw;
        min-height: 250px;
        margin: 5px;
        &__content {
            padding-left: 0px;
            padding-right: 0px;
            padding-top: 0px;
        }
    }
    .img__area {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
        ion-img {
            height: 200px;
        }
    }
    .card__header {
        font-size: 17px;
    }
}

@media (min-width: 576px) {
    .custom__carousel {
        &__card {
            width: 30.9vw;
        }
    }
}

@media (min-width: 768px) {
    .custom__carousel {
        &__card {
            width: 23.4vw;
        }
    }
}