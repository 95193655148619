.root {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 8px;
  --padding-bottom: 8px;
}
.card {
  max-width: 600px;
  margin: auto;
  min-height: 100%;
}
.logo {
  max-height: 200px;
  margin: auto;
  width: 100%;
  object-fit: contain;
  padding: 4px;
}
