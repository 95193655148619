.root {
  position: relative;
  width: 100%;
}
.image {
  height: 100%;
  width: 100%;
}
.details {
  position: absolute;
  bottom: 20px;
  right: 0px;
  left: 0px;
}
.title,
.subtitle {
  margin: 0;
}
