.root {
  position: relative;
}
.badge {
  position: absolute;
  border-radius: 20px;
  line-height: 10px;
  font-size: 10px;
  height: 14px;
  padding: 2px 4px;
}
.badge.ios {
  top: 0px;
  left: 0px;
}
.badge.md {
  top: -4px;
  left: -4px;
}
