.categoryView {
    width: 100%;
    &__card {
        width: 100%;
        margin: 0;
    }
    &__image {
        object-fit: cover;
        width: 100%;
    }
    &__title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
        text-align: center;
    }
}
