.servicesListContainer {
  padding: 0px !important;
  .service {
    padding: 4px !important;
    &__info {
      margin-inline-start: 10px;
      .title {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    &__card {
      cursor: pointer;
      margin-inline: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      height: 100%;
      margin-top: 0px;
      margin-bottom: 0px;
      -webkit-margin-before: 0px;
      -webkit-margin-end: 0px;
      -webkit-margin-after: 0px;
      -webkit-margin-start: 0px;
      -webkit-padding-before: 0px;
      -webkit-padding-end: 0px;
      -webkit-padding-after: 0px;
      -webkit-padding-start: 0px;
      --ion-background-color: white;
    }
    &__header {
      padding-inline: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      margin: 0px !important;
    }
    &__content {
      padding-inline: 0px;
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 5px;
      margin-bottom: 10px;
      padding-top: 5px;
      height: 50px;
      -webkit-margin-end: 0px;
      -webkit-margin-after: 0px;
      -webkit-margin-start: 5px;
      -webkit-padding-before: 10px;
      -webkit-padding-end: 10px;
      -webkit-padding-after: 0px;
      -webkit-padding-start: 0px;
    }
    &__badge {
      padding-inline: 0px;
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 0px;
      padding-top: 10px;
    }
    &__display {
      min-height: 150px !important;
      .service__image {
        ion-img {
          height: 175px;
        }
      }
    }
  }
  .category {
    width: 100%;
    margin: 0px;
    margin-top: 5px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.servicesListContainer {
  padding: 0px !important;
  .service__list {
    padding: 4px !important;
    &__image {
      flex-grow: 1;
      max-width: 180px;
      .img {
        max-width: 180px;
        height: 100%;
      }
    }
    &__info {
      margin-inline-start: 10px;
      .title {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    &__card {
      cursor: pointer;
      margin-inline: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      display: flex;
      height: 100%;
      margin-top: 0px;
      margin-bottom: 0px;
      --ion-background-color: white;
      -webkit-margin-before: 0px;
      -webkit-margin-end: 0px;
      -webkit-margin-after: 0px;
      -webkit-margin-start: 0px;
      -webkit-padding-before: 0px;
      -webkit-padding-end: 0px;
      -webkit-padding-after: 0px;
      -webkit-padding-start: 0px;
    }
    &__header {
      padding-inline: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      margin: 0px !important;
    }
    &__content {
      padding-inline: 0px;
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 5px;
      margin-bottom: 10px;
      padding-top: 5px;
      height: 50px;
      -webkit-margin-before: 0px;
      -webkit-margin-end: 0px;
      -webkit-margin-after: 0px;
      -webkit-margin-start: 5px;
      -webkit-padding-before: 10px;
      -webkit-padding-end: 10px;
      -webkit-padding-after: 0px;
      -webkit-padding-start: 0px;
    }
    &__badge {
      padding-inline: 0px;
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 0px;
      padding-top: 10px;
    }
    &__display {
      min-height: 150px !important;
      display: flex;
      align-items: center;
      padding-inline-start: 10px;
    }
  }
  .category {
    width: 100%;
    margin: 0px;
    margin-top: 5px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}
