.item {
  margin-top: 16px;
}

.label,
.select::part(text) {
  white-space: initial !important;
}

.alert {
  --max-width: auto !important;
  padding: 0 16px;
}
