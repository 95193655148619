.ion-padding-horizontal-small {
    --padding-right: var(--ion-padding-small, 8px);
    --padding-left: var(--ion-padding-small, 8px);
    padding-right: var(--ion-padding-small, 8px);
    padding-left: var(--ion-padding-small, 8px);
}

.ion-padding-xsmall {
    --padding-top: var(--ion-padding-xsmall, 4px);
    --padding-bottom: var(--ion-padding-xsmall, 4px);
    --padding-right: var(--ion-padding-xsmall, 4px);
    --padding-left: var(--ion-padding-xsmall, 4px);
    padding-top: var(--ion-padding-xsmall, 4px);
    padding-bottom: var(--ion-padding-xsmall, 4px);
    padding-right: var(--ion-padding-xsmall, 4px);
    padding-left: var(--ion-padding-xsmall, 4px);
}

@supports (padding-inline-start: 0) or (-webkit-padding-start: 0) {
    .ion-padding-xsmall {
        padding-left: unset;
        padding-right: unset;
        padding-inline-start: var(--ion-padding-xsmall, 4px);
        padding-inline-end: var(--ion-padding-xsmall, 4px);
    }
    .ion-padding-horizontal-small {
        padding-left: unset;
        padding-right: unset;
        padding-inline-start: var(--ion-padding-small, 8px);
        padding-inline-end: var(--ion-padding-small, 8px);
    }
}

.ion-padding-vertical-xsmall {
    --padding-top: var(--ion-padding-xsmall, 4px);
    --padding-bottom: var(--ion-padding-xsmall, 4px);
    padding-top: var(--ion-padding-xsmall, 4px);
    padding-bottom: var(--ion-padding-xsmall, 4px);
}

.ion-no-inner-padding-end {
    --inner-padding-end: 0;
}
