.ion-display-flex {
    display: flex;
}

.ion-display-block {
    display: block;
}

.ion-full-width {
    width: 100%;
}

.ion-full-height {
    height: 100%;
}

.ion-half-height {
    height: 50%;
}

.ion-max-width-half {
    max-width: 50%;
    --max-width: 50%;
}

.ion-flex-grow {
    flex-grow: 1;
}

.ion-height-auto {
    height: auto;
}

.ion-icon-xlarge {
    width: 72px;
    height: 72px;
}

.ion-input-no-spin::-webkit-outer-spin-button,
.ion-input-no-spin::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ion-no-background {
    background: none;
    --background: none;
}

.ion-no-border {
    border: 0;
}

.ion-overflow-hidden {
    overflow: hidden;
}

.ion-overflow-visible {
    overflow: visible !important;
}

.ion-object-fit-cover {
    object-fit: cover;
}

.ion-position-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ion-position-absolute {
    position: absolute;
}

.ion-no-opacity {
    opacity: 0;
}

.ion-pointer {
    cursor: pointer;
}

.ion-text-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ion-no-text-transform {
    text-transform: none;
}

.ion-icon-stroke-bold {
    --ionicon-stroke-width: 64px;
}

.ion-icon-stroke-light {
    --ionicon-stroke-width: 8px;
}

.ion-width-fit-content {
    --width: fit-content;
}

.ion-height-fit-content {
    --height: fit-content;
}
