.currentPrice {
  font-size: large;
  font-weight: bolder;
}
.oldPrice {
  margin-inline-end: 4px;
  font-size: medium;
}

.saving {
  margin: 0;
  font-size: 14px;
}

.currPrice,
.prevPrice,
.saving {
  white-space: nowrap;
}
