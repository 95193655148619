.root {
  position: absolute;
  margin: auto;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  width: 40px;
  height: 40px;
}
