.resetPasswordForm {
    min-height: calc(100% - 16px);
    --ion-background-color: white;
    max-width: 600px;
    margin: 8px auto;
    &__logo {
        max-height: 200px;
        margin: auto;
        width: 100%;
        object-fit: contain;
        margin-top: 16px;
    }
    &__input {
        margin-top: 16px;
    }
    &__submit {
        margin-top: 16px;
    }
    &__login {
        margin-top: 16px;
    }
    &__loginLink {
        margin: auto;
    }
}
