.filtermenu {
    &__category {
        border-radius: 0 !important;
    }
    &__footer {
        text-align: center;
        padding: 10px;
    }
}

.service-page {
    &__toolbar {
        &.ios {
            padding-top: 15px;
        }
    }
    .grid {
        padding: 0px;
        max-height: 50px;
        margin: 0px;
        margin-left: 10px;
        margin-right: 10px;
        .col {
            padding-left: 10px;
            padding-right: 10px;
            --ion-color: black;
        }
    }
    .toolbar {
        &__search {
            --box-shadow: none;
            .searchbar-input {
                padding-inline-start: 45px;
                padding-inline-end: 35px;
            }
        }
    }
    .categoryChip {
        margin-top: 10px;
    }
    .noSearchResults {
        p {
            width: 90%;
            margin: 20px auto;
            span {
                color: var(--ion-color-primary);
                font-weight: bolder;
            }
        }
    }
}

ion-menu.filtermenu {
    --width: 55vw;
    ion-list {
        padding: 0px;
    }
}