.invalidOOBCode{
    position: absolute;
    left: 50%;
    top: 30%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    --padding-top: 8px;
    --padding-end: 8px;
    --padding-start: 8px;
    --padding-bottom: 8px;
}