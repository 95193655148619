.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.itemsList {
  flex-grow: 1;
  ion-item.md {
    --ripple-color: var(--ion-color-primary);
  }
  ion-icon.ios {
    font-size: 24px;
  }
}

.languageSelector {
  width: 100%;
}

.itemSelected {
  ion-item {
    --background: var(--ion-color-medium);
  }
}

.footer {
  position: sticky;
  bottom: 0;
}
