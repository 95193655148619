.productsPage {
    .grid {
        padding: 0px;
        max-height: 50px;
        margin: 0px;
        margin-left: 10px;
        margin-right: 10px;
        .col {
            padding-left: 10px;
            padding-right: 10px;
            --ion-color: black;
        }
    }
    .noSearchResults {
        p {
            width: 90%;
            margin: 20px auto;
            span {
                color: var(--ion-color-primary);
                font-weight: bolder;
            }
        }
    }
}

.loadMoreContainer {
    text-align: center;
    padding: 1em;
    span {
        margin: 0 1em;
    }
}
